import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
  </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header





  // <head>
  //   <meta charSet="utf-8"/>
  //   <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no"/>
  //   <title>Home - Letchworth &amp; Baldock District</title>
  //   <meta name="description" content="Environment, Sustainability, Leadership, Nature" />
  //   <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.12.0/css/all.css"/>
  //   <link rel="stylesheet" href="assets/css/styles.min.css" />
  //   <link rel="stylesheet" href="assets/bootstrap/css/bootstrap.min.css" />
  //   <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800" />
  //   <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lora:400,700,400italic,700italic" />
  //   <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Nunito+Sans" />
  //   <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
  //   <link rel="stylesheet" href="assets/css/Article-List.css" />
  //   <link rel="stylesheet" href="assets/css/Card-Carousel-1.css" />
  //   <link rel="stylesheet" href="assets/css/Card-Carousel.css" />
  //   <link rel="stylesheet" href="assets/css/Features-Boxed.css" />
  //   <link rel="stylesheet" href="assets/css/Footer-Clean.css" />
  //   <link rel="stylesheet" href="assets/css/Navigation-Clean.css" />
  //   <link rel="stylesheet" href="assets/css/Navigation-with-Search.css" />
  // </head>
